import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.admin.cintasyriamalaysia.com", //https://api.csm.nadofficial.com https://api.admin.cintasyriamalaysia.com  http://localhost:8000
  withCredentials: true,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export default apiClient;
