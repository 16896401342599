import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import api from "../src/services/api";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const TaxLayout = React.lazy(() =>
  import("./containers/tax-exemption/TheLayout")
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/password/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/password/ResetPassword")
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      token: localStorage.getItem("token"),
      role: "",
    };
  }

  componentDidMount() {
    localStorage.getItem("loggedIn");
    localStorage.getItem("token");
    if (localStorage.getItem("token")) {
      let userStr = localStorage.getItem("users");
      let user = JSON.parse(userStr);
      let userRole = user.role;
      this.setState({ role: userRole });
    }
    this.checkToken();
  }

  login = (token) => {
    this.setState({
      loggedIn: true,
    });
    // sessionStorage.setItem("loggedIn", true);
    // localStorage.setItem("token", token);
  };

  checkToken() {
    const data = { user_token: this.state.token };
    if (data) {
      api.get("/api/checkToken", data).then((res) => {
        if (res.data.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      });
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => (
                <Login {...props} login={this.login} data={this.state} />
              )}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password/:id"
              name="Forgot Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              path="/tax"
              name="Tax Exemption"
              render={(props) => <TaxLayout {...props} />}
            />
            {/* <Route
              exact
              path="/"
              name="Home"
              render={(props) => (
                <Login {...props} login={this.login} data={this.state} />
              )}
            /> */}
            <Route
              path="/admin"
              name="Home"
              render={(props) => (
                <TheLayout
                  {...props}
                  loggedIn={this.state.loggedIn}
                  role={this.state.role}
                />
              )}
            />
            <Route
              path="/staff"
              name="staff"
              render={(props) => (
                <TheLayout
                  {...props}
                  loggedIn={this.state.loggedIn}
                  role={this.state.role}
                />
              )}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <TheLayout
                  {...props}
                  loggedIn={this.state.loggedIn}
                  role={this.state.role}
                />
              )}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
